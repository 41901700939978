/* global fetch */
import get from 'lodash/get'
import { getDeviceId, getXsrfToken, getJwt, getAuth0Tokens, isAuth0Enabled } from './ApiFetch'
import ApiModel from './ApiModel'
import Log from './log'
import { getNormalizedModels } from './normalize'
import FetchError from './FetchError'
import nativeApp from './util/nativeApp'
import nativeiOSApp from './util/nativeiOSApp'
import { ApiState } from './types'

const VALID_STATUSES = ['pending', 'queued', 'started', 'completed']

export type Job = {
  id: string,
  status: string,
  response: ApiState,
}

type ErrorResponse = {
  error: string,
  status: string,
}

export type JobResponse = {
  job: Job,
}

export const isJobResponse = (json: Record<string, any>): json is JobResponse =>
  json.job != null
const isErrorResponse = (json: Record<string, any>): json is ErrorResponse =>
  json.error != null && json.status != null

const JOB_CALL_API_PERIOD = 300 // in ms
const JOB_POLLING_TIMEOUT = 90 // in seconds

export const JOB_STATUS_SUCCESS = 'JOB_STATUS_SUCCESS'

export const getJobResult = async jobId => await new JobHandler().startWatchingJob(jobId)

export default class JobHandler {
  private readonly _url: string | null
  private _pollingStartTime: number
  private readonly _options: RequestInit

  constructor (url: string | null = null) {
    this._url = url
    this._pollingStartTime = -1

    this._options = {
      method: 'GET',
      headers: {
        'Auth-Device-ID': getDeviceId(),
        'Auth-Device-Type': 'browser',
        'Auth-Token-XSRF': getXsrfToken() as string,
        ...(getJwt() != null && { Authorization: `Bearer ${getJwt()}` }),
      },
      credentials: 'same-origin',
      cache: 'no-cache',
    }

    if (nativeApp().isHosted() || nativeiOSApp().isHosted()) {
      (this._options.headers as HeadersInit)['Auth-Host-Device-Type'] = nativeApp().isHosted()
        ? nativeApp().getDeviceType()
        : nativeiOSApp().getDeviceType()
      if (isAuth0Enabled()) {
        const { accessToken } = getAuth0Tokens()
        const railsJwt = getJwt()
        if (accessToken != null) {
          // eslint-disable-next-line dot-notation
          (this._options.headers as HeadersInit)['Authorization'] = accessToken
        } else if (railsJwt != null) {
          (this._options.headers as HeadersInit)['X-Consumer-Token'] = railsJwt
        }
      }
    }
  }

  startWatchingJob (jobId) {
    return this._fetchJobStatus(jobId)
  }

  handleJobResult (json: Job): Record<string, any> | Promise<Record<string, any>> {
    const { id, status, response } = json
    const currentState = get((ApiModel.reduxState.api as ApiState).jobs, [id], {}) as Job
    if (currentState.status !== status) {
      ApiModel.dispatch({
        type: JOB_STATUS_SUCCESS,
        successData: { values: getNormalizedModels({ job: json }).normalized },
      })
    }

    if (status === 'completed') {
      if (response == null || Object.keys(response).length === 0) {
        // FIX THIS HACK ASAP. There is a race condition in jobs response that
        // can sometimes cause the job to return status: completed before the response is stashed
        // and available to the client, so we get a response that says complete, but no data. This
        // is here to work around that issue for the coach tool and should not be sent
        // to production
        // TODO (NJC 2019-01-11) Is this still happening? I just bumped the log level to error -
        // check sentry in a week or two.
        Log.error('RESPONSE EMPTY, IGNORING STATUS FLAG')
        return this._scheduleCheck(id)
      }

      // resolve the original promise with the response
      return response
    } else if (VALID_STATUSES.includes(status)) {
      return this._scheduleCheck(id)
    } else {
      Log.warn(`Unexpected job status [${status}]`, json)
      return Promise.reject(
        new FetchError({ error: `Unexpected job status [${status}]` }, response, this._url),
      )
    }
  }

  _scheduleCheck (id) {
    const now = Date.now()
    if (this._pollingStartTime < 0) this._pollingStartTime = now
    const elapsed = (now - this._pollingStartTime) / 1000
    if (elapsed > JOB_POLLING_TIMEOUT) {
      return Promise.reject(
        new FetchError({ error: `Timed out waiting for job resolution [${id}]` }, null, this._url),
      )
    }

    return new Promise(resolve => {
      setTimeout(() => resolve(this._fetchJobStatus(id)), JOB_CALL_API_PERIOD)
    })
  }

  async _fetchJobStatus (id) {
    const url = `${process.env.API_ROOT}api/v1/jobs/${id}`
    const response = await fetch(url, this._options)
    const jobResponse = await response.json() as JobResponse | ErrorResponse

    if (!response.ok || isErrorResponse(jobResponse)) {
      throw new FetchError(jobResponse, response, this._url, true)
    } else {
      return this.handleJobResult(jobResponse.job)
    }
  }
}
