import Cookie from 'js-cookie'
import isNumber from 'lodash/isNumber'
import isEmpty from 'lodash/isEmpty'
import { v4 as uuidv4 } from 'uuid'
import 'whatwg-fetch'
import JobHandler, { isJobResponse } from './JobHandler'
import Log from './log'
import { DELETE, getNormalizedModels } from './normalize'
import { IN_IFRAME } from '@app/js/constants'
import ApiModel, { PathOptions } from './ApiModel'
import Action from './Action'
import FetchError from './FetchError'
import nativeApp from './util/nativeApp'
import nativeiOSApp from './util/nativeiOSApp'
import { ApiFailureAction, ApiKey, ApiSuccessAction, ApiSuccessValue } from './types'
import queryString from 'query-string'
import { Firebase } from './Firebase'
import { skipNullQueryString } from '@app/js/lib/queryString'
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js'

export type ApiFetchOptions = PathOptions & {
  /**
   * The data for the request, if it's a type that sends a JSON body
   */
  data?: Record<string, any>,
  /**
   * Will allow a request to go through even if there is a failed request sitting in apiPending in
   * the redux store.
   */
  ignoreFailure?: boolean,
  /**
   * If predicate returns false (default is true), the apiPending reducer will not record a failure
   * on this endpoint. Use this if the model handles failure responses in its on custom reducer.
   * NOTE: recordFailure should be a pure function, and specifically should not dispatch any redux
   * actions.
   */
  recordFailure?: (response: Response) => boolean,
  /**
   * For the rare case when the api key doesn't match the endpoint.
   */
  apiKeyOverride?: ApiKey[],
  /**
   * If provided, indicates a key under which ids returned from this call should end up in modelMap
   * (in the store). Examples:
   *   ['user_id']:
   * Any models returned that have a user_id and id on them will end up mapped in the store
   * via:
   *   ['modelMap', 'user_id', '<user_id>', '<modelName>', [1, 2, 3, 4]]
   *
   *   ['user_id', 42]:
   * Any models returned that have an id on them will end up mapped in the store via:
   *   ['modelMap', 'user_id', 42, '<modelName>', [5, 6, 7, 8]]
   * Exception: if the model has its own user_id, that's used instead
   *
   *   ['activity_slug', 'triage']:
   * Any models returned that have an id on them will end up mapped in the store via:
   *   ['modelMap', 'activity_slug', 'triage', '<modelName>', [9, 10, 11]]
   */
  modelMapKey?: ApiKey[],
  /**
   * If provided, will include extra headers to be applied to the request.
   */
  headers?: Record<string, string>,
  /**
   * If true, the response will not be merged into the standard API store, nor will sideloaded
   * models be processed.
   */
  noCache?: boolean,
  maxAttempts?: number,
  /**
   * A function that takes the action and returns an action to be dispatched. Default does nothing.
   */
  successActionMutator?: (action: ApiSuccessAction) => ApiSuccessAction,
}
type URISearch = {
  // eslint-disable-next-line camelcase
  program_activity_id: string
  // eslint-disable-next-line camelcase
  deep_link: string
}

class RetryError extends Error { }

export const getXsrfToken = () => Cookie.get('jwt-xsrf')

export const CALL_API = Symbol('Call API')

const DEVICE_ID = 'deviceId'

export const cookieDomain = process.env.SERVER_HOST === 'localhost' ? 'localhost' : '.ableto.com'

export function getDeviceId () {
  if (nativeApp().isHosted()) return nativeApp().getDeviceId()

  const deviceId = window.localStorage.getItem(DEVICE_ID) ?? uuidv4()
  window.localStorage.setItem(DEVICE_ID, deviceId)
  return deviceId
}

export function clearDeviceID () {
  if (nativeApp().isHosted()) return

  window.localStorage.removeItem(DEVICE_ID)
}

export const loginRedirect = () => {
  if (IN_IFRAME) {
    return `/verify-login${window.location.search}`
  } else {
    const fromDeepLink = (queryString.parse(window.location.search) as URISearch).deep_link
    if (fromDeepLink != null) {
      const currentParams = queryString.parse(window.location.search) as object
      const query = skipNullQueryString({
        ...currentParams,
      })
      const path = `${window.location.pathname}?${query}`

      return `/?login_redirect=${encodeURIComponent(path)}`
    }
    return `/?login_redirect=${encodeURIComponent(window.location.pathname)}`
  }
}

const JWT_LOCAL_STORAGE_KEY = 'ableto-jwt'
const AUTH0_ACCESS_TOKEN_KEY = 'ableto-aat'
const AUTH0_IDP_TOKEN_KEY = 'ableto-idp'

export const getJwt = () => {
  if (nativeApp().isHosted()) {
    let nativeAppJwt: string | null = nativeApp().getJwtToken()

    if (!nativeAppJwt) {
      nativeAppJwt = window.localStorage.getItem(JWT_LOCAL_STORAGE_KEY)
    }

    nativeAppJwt && storeJwt(nativeAppJwt)

    return !nativeAppJwt ? undefined : nativeAppJwt
  }

  if (nativeiOSApp().isHosted() && !!nativeiOSApp().getJwtToken) {
    let nativeiOSAppJwt = nativeiOSApp().getJwtToken()

    if (!nativeiOSAppJwt) {
      nativeiOSAppJwt = window.localStorage.getItem(JWT_LOCAL_STORAGE_KEY)
    }

    nativeiOSAppJwt && storeJwt(nativeiOSAppJwt)

    return !nativeiOSAppJwt ? undefined : nativeiOSAppJwt
  }

  const jwt = window.localStorage.getItem(JWT_LOCAL_STORAGE_KEY)
  return jwt === '' ? undefined : jwt
}

export const getAuth0Tokens = () => {
  let accessToken: string | null
  let idpToken: string | null

  if (nativeApp().isHosted() || nativeiOSApp().isHosted()) {
    accessToken = nativeApp().isHosted() ? nativeApp().getAuth0AccessToken() : nativeiOSApp().getAuth0AccessToken()
    idpToken = nativeApp().isHosted() ? nativeApp().getAuth0IdToken() : nativeiOSApp().getAuth0IdToken()
    storeAuth0Tokens(idpToken, accessToken)
    return { accessToken, idpToken }
  }

  accessToken = window.sessionStorage.getItem(AUTH0_ACCESS_TOKEN_KEY)
  idpToken = window.sessionStorage.getItem(AUTH0_IDP_TOKEN_KEY)

  return { accessToken, idpToken }
}

export const storeJwt = (jwt: string) => window.localStorage.setItem(JWT_LOCAL_STORAGE_KEY, jwt)
export const storeAuth0Tokens = (accessToken: string, idpToken: string) => {
  window.sessionStorage.setItem(AUTH0_ACCESS_TOKEN_KEY, accessToken)
  window.sessionStorage.setItem(AUTH0_IDP_TOKEN_KEY, idpToken)
}
export const clearJwt = () => {
  window.localStorage.removeItem(JWT_LOCAL_STORAGE_KEY)

  /*
    clearJwt is called when the Rails server has indicated that our current JWT is expired.
    If the Rails user session is no longer valid, we also want to ensure that the Firebase session
    is also ended.

    We need to disable some TS rules here because the Firebase module hasn't been converted yet.
  */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  Firebase.auth.signOut().catch(() => {
    throw new Error('Firebase signout unsuccessful')
  })
}
export const clearAuth0Tokens = () : void => {
  window.sessionStorage.removeItem(AUTH0_ACCESS_TOKEN_KEY)
  window.sessionStorage.removeItem(AUTH0_IDP_TOKEN_KEY)
}

let _auth0Client: Auth0Client

export const auth0Client = async () => {
  return _auth0Client || (
    _auth0Client = await createAuth0Client({
      domain: process.env.AUTH0_DOMAIN || '',
      clientId: process.env.AUTH0_CLIENT_ID || '',
      authorizationParams: {
        audience: process.env.AUTH0_AUDIENCE,
      },
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      cacheLocation: 'localstorage',
    })
  )
}

export const logoutAndClearAuth0Tokens = async () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { logout } = queryString.parse(window.location.search)
  if (logout) {
    return Promise.resolve()
  }

  clearAuth0Tokens()

  const client = await auth0Client()
  return await client.logout({ logoutParams: { returnTo: `${window.location.origin}?logout=true` } })
}

export const AUTH0_FEATURE_FLAG_KEY = 'auth0_login'
export const isAuth0Enabled = () => window.sessionStorage.getItem(AUTH0_FEATURE_FLAG_KEY) === 'true'

// TODO (NJC): OMG SPECS
export default class ApiFetch {
  readonly action: Action
  readonly recordFailure: (response: Response) => boolean
  readonly url: string
  readonly options: RequestInit

  private attempts: number
  private _response: Response | null = null
  private _responseText: string | null = null

  private readonly requestType: string
  private readonly successType: string
  private readonly failureType: string
  private readonly maxAttempts: number
  private readonly model: ApiModel
  private readonly ignoreFailure?: boolean
  private readonly apiKeyOverride?: ApiKey[]
  private readonly modelMapKey?: ApiKey[]
  private readonly noCache: boolean
  private readonly successActionMutator: (action: ApiSuccessAction) => ApiSuccessAction

  /**
   * Constructor for a fetch request to be handled by the API middleware
   *
   * @param {ApiModel} model An instance of ApiModel
   * @param {Action} action An instance of the Action enum
   * @param {ApiFetchOptions} options Any optional parameters needed for this request.
   */
  constructor (
    model: ApiModel,
    action: Action,
    options: ApiFetchOptions = {},
  ) {
    const {
      data,
      ignoreFailure = false,
      recordFailure = () => true,
      apiKeyOverride,
      modelMapKey,
      headers,
      noCache = false,
      maxAttempts = 3,
      successActionMutator = action => action,
    } = options
    const [requestType, successType, failureType] = action.getTypes(model)
    this.requestType = requestType
    this.successType = successType
    this.failureType = failureType
    this.attempts = 0
    this.maxAttempts = maxAttempts
    this.model = model
    this.action = action
    this.url = model.getUrl(options)

    const headersInit: HeadersInit = {
      // These headers are required for guest user access
      'Auth-Device-ID': getDeviceId(),
      'Auth-Device-Type': 'browser',

      ...headers,
    }
    this.options = {
      method: action.method,
      headers: headersInit,
      credentials: 'include',
      cache: 'no-store',
    }

    if (nativeApp().isHosted()) {
      headersInit['Auth-Host-Device-Type'] = nativeApp().getDeviceType()
    } else if (nativeiOSApp().isHosted()) {
      headersInit['Auth-Host-Device-Type'] = nativeiOSApp().getDeviceType()
    }

    if (action.hasBody) {
      if (data instanceof window.FormData) {
        this.options.body = data
      } else {
        this.options.body = JSON.stringify(data)
        headersInit['Content-Type'] = 'application/json'
      }
    }

    const jwt = getJwt()

    if (isAuth0Enabled()) {
      const { accessToken, idpToken } = getAuth0Tokens()
      if (accessToken && idpToken) {
        headersInit.Authorization = `Bearer ${accessToken}`
        // todo: Conditionally set this header if the current user is not a guest.
        //       This means user has already been associated with the auth0 sub_id.
        //       The idpToken is a bit over 1KB in size.
        headersInit['X-Idp-Token'] = idpToken
      } else if (jwt != null) {
        headersInit['X-Consumer-Token'] = jwt
      }
    } else if (jwt != null) {
      headersInit.Authorization = `Bearer ${jwt}`
    } else {
      // Migration path - if we don't have a stashed JWT, and we _do_ have access to a jwt-xsrf
      // cookie, then we're migrating this logged in user to use bearer tokens
      const xsrfToken = getXsrfToken()
      if (xsrfToken != null) headersInit['Auth-Token-XSRF'] = xsrfToken
    }

    this.ignoreFailure = ignoreFailure
    this.recordFailure = recordFailure
    this.apiKeyOverride = apiKeyOverride
    this.modelMapKey = modelMapKey
    this.noCache = noCache
    this.successActionMutator = successActionMutator
  }

  get dispatchObject () {
    return { [CALL_API]: this, type: 'API_CALL' }
  }

  get networkFieldsForLog () {
    if (this._response == null) return ''

    const fields = {
      response: {
        headers: [...this._response.headers.entries()]
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
        status: this._response.status,
        url: this._response.url,
        text: this._responseText == null ? '[Response not read]' : this._responseText,
      },
      request: {
        url: this.url,
        method: this.options.method,
        headers: this.options.headers,
        body: this.options.body,
      },
    }
    return JSON.stringify(fields, null, '  ')
  }

  /**
   * Performs the API call encapsulated in this object and returns a promise that will eventually
   * resolve to an object that should be dispatched to the redux store.
   */
  fetch (): Promise<Record<string, any> | undefined> {
    Log.debug(`Performing API request [${this.options.method as string}, ${this.url}]`)
    this._response = null
    this._responseText = null
    this.attempts++

    return window.fetch(this.url, this.options).then(response => {
      this._response = response

      const authorization = isAuth0Enabled()
        ? response.headers.get('X-Consumer-Token')
        : response.headers.get('Authorization')

      if (authorization != null) {
        if (isAuth0Enabled()) {
          storeJwt(authorization)
        } else {
          const token = authorization.replace('Bearer ', '')
          storeJwt(token)
        }
      }

      if (response.status === 204) {
        // 204 == no content
        return {}
      } else if (
        response.status === 401 &&
        window.location.pathname !== '/' &&
        !this.url.includes('/api/v1/jwt')
      ) {
        // Let NativeApp perform its own logout functionality
        // as opposed to the redirect logic used by the webapp
        if (nativeApp().isHosted()) {
          if (nativeApp().isReactNative()) {
            // The reason argument is only supported in React Native
            // and not android native. It's used to keep backwards compatibility with
            // Native Android and its signOut function, while providing additional
            // signOut behaviour in React Native, based on the logout reason
            nativeApp().signOut('session_expired')
          } else {
            nativeApp().signOut()
          }

          return
        }

        // if we ever receive a 401, send us to the login dialog (hosted on the `/` path)
        const redirect = loginRedirect()
        response.json().then(({ error }) => {
          if (error === 'Signature has expired') {
            clearJwt()
            clearAuth0Tokens();
            (window as Window).location = `${redirect}&reason=timed_out`
          } else {
            throw new Error('Unknown 401 reason')
          }
        }).catch(() => {
          // fall back to a redirect with no reason if anything goes wrong reading the response data
          (window as Window).location = redirect
        })
      } else if (
        // 502 responses should be rare, but will show the user the 5000 error screen.
        // This happens when nginx is unable to communicate with rails or the request
        // from nginx to rails is interrupted. In this event, we will retry once before
        // failing the response.
        response.status === 502 &&
        this.attempts < this.maxAttempts
      ) {
        Log.warn(`API request failed with 502 status and is being retried for URL: ${this.url}`)
        return window.Promise.reject(new RetryError())
      } else {
        return response.text().then(text => {
          this._responseText = text
          if (isEmpty(text)) {
            if (response.status === 200 || response.status === 201) {
              // Assume this is due to some of our APIs returning a 200 with no content instead of
              // the 204 they should, and swallow the error.
              this.model.logFailure(null, response, this,
                'Ignoring an empty body, this endpoint should probably return `head :no_content`')
              return {}
            }
          }

          const json = JSON.parse(text) as Record<string, any>
          if (!response.ok || json.error) {
            return window.Promise.reject({ json, response })
          } else if (isJobResponse(json)) {
            return new JobHandler(this.url).handleJobResult(json.job)
          } else {
            return json
          }
        })
      }
    }).then(this.apiSuccess, this.apiFailure)
  }

  apiSuccess = (json = {}): ApiSuccessAction | undefined => {
    const method = this.options.method as string
    Log.debug(`Api request success [${method}, ${this.url}]`, this.options)
    try {
      const toDispatch: Partial<ApiSuccessAction> = {
        type: this.successType,
        response: json,
        method,
        options: this.options,
        url: this.url,
      }

      if (this.noCache) {
        toDispatch.successData = { values: [], modelMap: [] }
        return toDispatch as ApiSuccessAction
      }

      const [resource, id] = this.url.replace(/.*api\/v1\/([^?]*)\??.*/, '$1').split('/')
      if (method === 'DELETE') {
        const key = this.apiKeyOverride != null
          ? this.apiKeyOverride
          : id ? [resource, isNumber(id) ? parseInt(id) : id] : [resource]
        const modelMap: ApiSuccessValue[] = []
        if (this.modelMapKey != null) {
          modelMap.push({ key: [...this.modelMapKey, ...key], value: DELETE })
        }
        toDispatch.successData = { values: [{ key, value: DELETE }], modelMap }
        return toDispatch as ApiSuccessAction
      }

      const { normalized, modelMap } =
        getNormalizedModels(json, { modelMapKey: this.modelMapKey, resource, id })
      toDispatch.successData = { values: normalized, modelMap }
      return this.successActionMutator(toDispatch as ApiSuccessAction)
    } catch (error) {
      Log.error('Error processing API success', error)
    }
  }

  apiFailure = (
    error: FetchError | RetryError | Record<string, any>,
  ): ApiFailureAction | Promise<Record<string, any> | undefined> => {
    if (error instanceof RetryError) {
      return this.fetch()
    }

    if ((error instanceof FetchError && !error.shouldUseServerMessage) ||
        (error.json == null && error.response == null)) {
      Log.error('Error handling API response', error, this.networkFieldsForLog)
      return {
        type: this.failureType,
        url: this.url,
        error: { message: 'Error handling Api response' },
        options: this.options,
      }
    }

    const { json, response } = error as {
      json: { error?: { message: string } | string, errors: string[] },
      response: Response
    }
    this.model.logFailure(json, response, this)
    // TODO (NJC) Make the error response from our API consistent
    let jsonError: { message: string }
    if (typeof json?.error === 'object') {
      jsonError = json.error
    } else if (json?.error != null) {
      jsonError = { message: json.error }
    } else if (json?.errors != null) {
      jsonError = { message: json.errors[0] }
    } else {
      jsonError = { message: 'Something bad happened' }
    }
    return {
      type: this.failureType,
      url: this.url,
      error: jsonError,
      response,
      options: this.options,
      recordFailure: this.recordFailure,
    }
  }
}
